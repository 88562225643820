import type { CustomElement } from '@integrabeauty/custom-elements';
import { vote } from '../../../lib/yotpo-vote.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders thumbs up / thumbs down icons for the yotpo review or a question. It handles yotpo api
 * calls on upvote or downvote.
 */
class YotpoReviewDetailActions extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-update',
      'data-votes-down',
      'data-votes-up'
    ];
  }

  readonly dataset!: {
    /**
     * Describes is element created for review or question. Used to determine what description
     * render to the user.
     */
    detailType: string;

    /**
     * Yotpo review id to which the review in this element pertain
     */
    reviewId: string;

    /**
     * Timestamp attribute triggering render
     */
    update: string;

    /**
     * Yotpo votes down number for the review in this element pertain
     */
    votesDown: string;

    /**
     * Yotpo votes up number for the review in this element pertain
     */
    votesUp: string;
  };

  public shadowRoot!: ShadowRoot;
  private voted: boolean;
  private votesUp: number;
  private votesDown: number;
  private onUpVoteBound = this.onUpVote.bind(this);
  private onDownVoteBound = this.onDownVote.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;

    this.voted = false;
  }

  public connectedCallback() {
    this.votesUp = parseInt(this.dataset.votesUp, 10);
    this.votesDown = parseInt(this.dataset.votesDown, 10);

    const thumbUp = this.shadowRoot.querySelector('.thumb-up');
    thumbUp?.addEventListener('click', this.onUpVoteBound);
    const thumbDown = this.shadowRoot.querySelector('.thumb-down');
    thumbDown?.addEventListener('click', this.onDownVoteBound);

    this.render();
  }

  public disconnectedCallback() {
    const thumbUp = this.shadowRoot.querySelector('.thumb-up');
    thumbUp?.removeEventListener('click', this.onUpVoteBound);

    const thumbDown = this.shadowRoot.querySelector('.thumb-down');
    thumbDown?.removeEventListener('click', this.onDownVoteBound);
  }

  public attributeChangedCallback(name: string, _oldValue: string, _newValue: string) {
    if (!this.isConnected) {
      return;
    }

    if (name === 'data-votes-down') {
      this.votesDown = parseInt(this.dataset.votesDown, 10);
    } else if (name === 'data-votes-up') {
      this.votesUp = parseInt(this.dataset.votesUp, 10);
    } else if (name === 'data-update') {
      this.render();
    }
  }

  private onUpVote(event: Event) {
    if (this.voted) {
      return;
    }

    const target = <HTMLElement>event.currentTarget;
    const parentContainer = target.parentElement.parentElement;
    parentContainer.classList.add('loading');

    const voteType = this.dataset.detailType === 'review' ? 'reviews' : 'answers';
    vote(voteType, this.dataset.reviewId, 'up').then(() => {
      parentContainer.classList.remove('loading');
      this.voted = true;
      this.votesUp += 1;
      this.render();
    }).catch((error) => {
      console.error(error);
      parentContainer.classList.remove('loading');
    });
  }

  private onDownVote(event: Event) {
    if (this.voted) {
      return;
    }

    const target = <HTMLElement>event.currentTarget;
    const parentContainer = target.parentElement.parentElement;
    parentContainer.classList.add('loading');

    const voteType = this.dataset.detailType === 'review' ? 'reviews' : 'answers';
    vote(voteType, this.dataset.reviewId, 'down').then(() => {
      parentContainer.classList.remove('loading');
      this.voted = true;
      this.votesDown += 1;
      this.render();
    }).catch((error) => {
      console.error(error);
      parentContainer.classList.remove('loading');
    });
  }

  private render() {
    const voteContainer = this.shadowRoot.querySelector<HTMLElement>('.customer-review-vote');
    const questionElement = voteContainer.querySelector('.helpful-question');

    if (this.dataset.detailType === 'review') {
      questionElement.textContent = 'Was This Review Helpful?';
    } else if (this.dataset.detailType === 'question') {
      questionElement.textContent = 'Was This Question Helpful?';
    }

    voteContainer.querySelector('.up-votes').textContent = `${this.votesUp}`;
    voteContainer.querySelector('.down-votes').textContent = `${this.votesDown}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'yotpo-review-detail-actions': YotpoReviewDetailActions;
  }
}

if (!customElements.get('yotpo-review-detail-actions')) {
  customElements.define('yotpo-review-detail-actions', YotpoReviewDetailActions);
}
